<template>
    <div class="grid gap-8">
        <router-link to="/dashboard/employments" class="flex gap-2">
            <KeyboardArrowLeftIcon class="h-6" />
            <span class="text-lg leading-6 font-semibold">{{ $t('languageAssessment.backToAllEvaluations') }}</span>
        </router-link>

        <div v-if="store.application" class="mt-8 grid gap-4">
            <section v-if="!directusStore.userHasRole('applicant')" class="grid gap-4">
                <div class="rounded-lg grid grid-cols-[1fr_auto] gap-2 md:gap-4">
                    <div>
                        <div class="font-bold text-2xl text-dark-green-900">{{ store.application.first_name }} {{
                            store.application.last_name }}</div>
                        <div class="text-dark-green-100 print:hidden">BCC {{ store.application.local_church.name }}</div>

                        <div v-if="directusStore.userHasRole('employer')" class="mt-5 mr-4 print:hidden">
                            <h3 class="col-span-full | text-xl font-bold text-dark-green-500 mb-2">{{ getEmployerName() }}
                            </h3>
                            <label class="font-bold mb-1 inline-block">{{ t('interest.rate.title') }}</label>
                            <v-select placeholder="---" v-model="store.interest_rate" :reduce="option => option.value"
                                :options="store.interest_rates" />
                        </div>

                        <div v-if="directusStore.isAdmin() && store.application.interests.length"
                            class="mt-5 mr-4 print:hidden">
                            <h3 class="col-span-full | text-xl font-bold text-dark-green-500 mb-2">{{
                                t('interest.rate.title') }}</h3>

                            <div v-for="(interest, index) in store.application.interests" :key="index">
                                <div class="mb-1 inline-block"><span class="font-bold">{{
                                    interest.employer_id.legal_entity_name }}</span>: {{ t('interest.rate.levels.' +
        interest.rate) }}</div>
                            </div>
                        </div>

                        <div class="mt-5 print:hidden"
                            v-if="store.application.employment_comment && directusStore.isAdmin()">
                            <p class="font-bold mb-1">{{ t('interest.employment_comment') }}</p>
                            <div v-html="store.application.employment_comment"></div>
                        </div>
                    </div>

                    <div v-if="store.application.profile_picture" class="print:absolute print:right-0 print:top-0">
                        <img :src="directus._url + '/assets/' + store.application.profile_picture.id + '?access_token=' + store.access_token"
                            class="rounded-lg" width="150" />
                    </div>
                </div>

                <div class="mt-4 rounded-lg print:relative hide-bg-print">
                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table print:w-full">
                        <h3 class="col-span-full print:w-full | text-xl font-bold text-dark-green-500 ">{{
                            t('form.general_info.title') }}</h3>

                        <div class="col-span-full">
                            <p class="font-bold mb-1">{{ t('form.gender.title') }}</p>
                            <span>{{ t('form.gender.' + store.application.gender) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.first_name.title') }}</p>
                            <span>{{ store.application.first_name }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.last_name.title') }}</p>
                            <span>{{ store.application.last_name }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.address.title') }}</p>
                            <span>{{ store.application.address }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.postal_code.title') }}</p>
                            <span>{{ store.application.postal_code }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.city.title') }}</p>
                            <span>{{ store.application.city }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.country.title') }}</p>
                            <span>{{ store.application.country }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.birth_date.title') }}</p>
                            <span>{{ getFormattedDate(store.application.birth_date) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.birth_place.title') }}</p>
                            <span>{{ store.application.birth_place }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.country_code.title') }}</p>
                            <span>{{ store.application.country_code }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.phone_number.title') }}</p>
                            <span>{{ store.application.phone_number }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.email_address.title') }}</p>
                            <span>{{ store.application.email_address }}</span>
                        </div>

                        <div class="print:hidden">
                            <p class="font-bold mb-1">{{ t('dashboard.local_church') }}</p>
                            <span>{{ store.application.local_church?.name }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.personal_info') }}</h3>

                        <div class="col-span-full">
                            <p class="font-bold mb-1">{{ t('form.preferable_tasks_in_local_church.title') }}</p>
                            <span>{{ store.application.preferable_tasks_in_local_church || '-' }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.work_experience') }}</h3>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.full_time_job.title') }}</p>
                            <span>{{ store.application.full_time_job ? t('form.yesOrNo.' + store.application.full_time_job) : '-' }}</span>
                        </div>

                        <div class="dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.areasOfKnowledge.title') }}</p>
                            <span v-for="(value, index) in store.application.area_of_knowledge" :key="index">{{ t('form.areasOfKnowledge.' + value) }}</span>
                            <span v-if="!store.application.area_of_knowledge?.length">-</span>
                        </div>

                        <div class="col-span-full">
                            <p class="font-bold mb-1">{{ t('form.work_years_of_experience.title') }}</p>
                            <span>{{ store.application.work_years_of_experience || '-' }}</span>
                        </div>

                        <h4 class="col-span-full | font-bold text-dark-green-500 mt-4">{{ t('form.work_reference_person.title') }}</h4>

                        <p class="col-span-full | font-bold mb-1">{{ t('form.work_reference_person.reference') }} 1</p>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.name') }}</p>
                            <span>{{ store.application.work_reference_person1_name || '-' }}</span>
                        </div>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.phone_number') }}</p>
                            <span>{{ store.application.work_reference_person1_phone_number || '-' }}</span>
                        </div>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.email_address') }}</p>
                            <span>{{ store.application.work_reference_person1_email_address || '-' }}</span>
                        </div>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.work_type') }}</p>
                            <span>{{ store.application.work_reference_person1_work_type || '-' }}</span>
                        </div>

                        <p class="col-span-full | font-bold mb-1 mt-2">{{ t('form.work_reference_person.reference') }} 2</p>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.name') }}</p>
                            <span>{{ store.application.work_reference_person2_name || '-' }}</span>
                        </div>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.phone_number') }}</p>
                            <span>{{ store.application.work_reference_person2_phone_number || '-' }}</span>
                        </div>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.email_address') }}</p>
                            <span>{{ store.application.work_reference_person2_email_address || '-' }}</span>
                        </div>
                        <div>
                            <p class="font-bold mb-1">{{ t('form.work_reference_person.work_type') }}</p>
                            <span>{{ store.application.work_reference_person2_work_type || '-' }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.knowledgeExperienceAreas.title') }}</h3>

                        <div class="col-span-full print:!hidden">
                            <p class="font-bold mb-4">{{ t('form.knowledgeExperienceAreas.subtitle') }}</p>

                            <div class="relative col-span-full">
                                <table class="w-full text-left">
                                    <thead class="sticky top-0 z-[3]">
                                        <tr>
                                            <th class="py-4 px-4 min-w-[200px] sticky left-0 z-[3] bg-isbre-700 rounded-tl-lg"
                                                scope="col">{{ t('form.knowledgeExperienceAreas.area') }} / {{ t('form.knowledgeExperienceAreas.years') }}</th>
                                            <th class="py-4 px-4 min-w-[50px] bg-isbre-700 text-center" scope="col"
                                                v-for="index in 5" :key="index">{{ index }}</th>
                                            <th class="py-4 px-4 min-w-[200px] bg-isbre-700 rounded-tr-lg text-center"
                                                scope="col">{{ t('form.knowledgeExperienceAreas.interest') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="relative z-[1]">
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.kindergarten_experience || store.application.groundwork_landscaping_experience || store.application.first_aid_experience || store.application.car_detailing_experience || store.application.stocktaking_experience || store.application.pruefservice_experience || store.application.cleaning_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.diverse') }}</td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.kindergarten_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.kindergarten_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="kindergarten_experience" :value="index" v-model="store.application.kindergarten_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="kindergarten_interest" v-model="store.application.kindergarten_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.groundwork_landscaping_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.groundwork_landscaping_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="groundwork_landscaping_experience" :value="index" v-model="store.application.groundwork_landscaping_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="groundwork_landscaping_interest" v-model="store.application.groundwork_landscaping_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.first_aid_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.first_aid_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="first_aid_experience" :value="index" v-model="store.application.first_aid_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="first_aid_interest" v-model="store.application.first_aid_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.car_detailing_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.car_detailing_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="car_detailing_experience" :value="index" v-model="store.application.car_detailing_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="car_detailing_interest" v-model="store.application.car_detailing_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.stocktaking_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.stocktaking_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="stocktaking_experience" :value="index" v-model="store.application.stocktaking_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="stocktaking_interest" v-model="store.application.stocktaking_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.pruefservice_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.pruefservice_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="pruefservice_experience" :value="index" v-model="store.application.pruefservice_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="pruefservice_interest" v-model="store.application.pruefservice_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.cleaning_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.cleaning_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="cleaning_experience" :value="index" v-model="store.application.cleaning_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="cleaning_interest" v-model="store.application.cleaning_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.accounting_experience || store.application.logistics_experience || store.application.event_arrangement_leadership_experience || store.application.office_administration_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.office_work') }}</td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.accounting_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.accounting_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="accounting_experience" :value="index" v-model="store.application.accounting_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="accounting_interest" v-model="store.application.accounting_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.logistics_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.logistics_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="logistics_experience" :value="index" v-model="store.application.logistics_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="logistics_interest" v-model="store.application.logistics_interest" />
                                            </td>
                                        </tr>                                                
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.event_arrangement_leadership_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.event_arrangement_leadership_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="event_arrangement_leadership_experience" :value="index" v-model="store.application.event_arrangement_leadership_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="event_arrangement_leadership_interest" v-model="store.application.event_arrangement_leadership_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.office_administration_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.office_administration_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="office_administration_experience" :value="index" v-model="store.application.office_administration_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="office_administration_interest" v-model="store.application.office_administration_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.construction_installation_experience || store.application.ventilation_installation_experience || store.application.glass_railing_installation_experience || store.application.plumbing_experience || store.application.electrician_experience || store.application.automatician_low_voltage_fitter_experience || store.application.industrial_electricity_textile_production_experience || store.application.skilled_worker_concrete_experience || store.application.machine_operator_experience || store.application.carpenter_woodworking_with_trade_certificate_experience || store.application.carpenter_woodworking_assistant_experience || store.application.cabinet_maker_experience || store.application.painting_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.construction') }}</td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.construction_installation_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.construction_installation_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="construction_installation_experience" :value="index" v-model="store.application.construction_installation_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="construction_installation_interest" v-model="store.application.construction_installation_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.ventilation_installation_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.ventilation_installation_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="ventilation_installation_experience" :value="index" v-model="store.application.ventilation_installation_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="ventilation_installation_interest" v-model="store.application.ventilation_installation_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.glass_railing_installation_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.glass_railing_installation_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="glass_railing_installation_experience" :value="index" v-model="store.application.glass_railing_installation_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="glass_railing_installation_interest" v-model="store.application.glass_railing_installation_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.plumbing_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.plumbing_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="plumbing_experience" :value="index" v-model="store.application.plumbing_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="plumbing_interest" v-model="store.application.plumbing_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.electrician_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.electrician_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="electrician_experience" :value="index" v-model="store.application.electrician_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="electrician_interest" v-model="store.application.electrician_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.automatician_low_voltage_fitter_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.automatician_low_voltage_fitter_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="automatician_low_voltage_fitter_experience" :value="index" v-model="store.application.automatician_low_voltage_fitter_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="automatician_low_voltage_fitter_interest" v-model="store.application.automatician_low_voltage_fitter_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.industrial_electricity_textile_production_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.industrial_electricity_textile_production_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="industrial_electricity_textile_production_experience" :value="index" v-model="store.application.industrial_electricity_textile_production_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="industrial_electricity_textile_production_interest" v-model="store.application.industrial_electricity_textile_production_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.skilled_worker_concrete_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.skilled_worker_concrete_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="skilled_worker_concrete_experience" :value="index" v-model="store.application.skilled_worker_concrete_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="skilled_worker_concrete_interest" v-model="store.application.skilled_worker_concrete_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.machine_operator_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.machine_operator_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="machine_operator_experience" :value="index" v-model="store.application.machine_operator_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="machine_operator_interest" v-model="store.application.machine_operator_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.carpenter_woodworking_with_trade_certificate_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.carpenter_woodworking_with_trade_certificate_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="carpenter_woodworking_with_trade_certificate_experience" :value="index" v-model="store.application.carpenter_woodworking_with_trade_certificate_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="carpenter_woodworking_with_trade_certificate_interest" v-model="store.application.carpenter_woodworking_with_trade_certificate_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.carpenter_woodworking_assistant_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.carpenter_woodworking_assistant_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="carpenter_woodworking_assistant_experience" :value="index" v-model="store.application.carpenter_woodworking_assistant_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="carpenter_woodworking_assistant_interest" v-model="store.application.carpenter_woodworking_assistant_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.cabinet_maker_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.cabinet_maker_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="cabinet_maker_experience" :value="index" v-model="store.application.cabinet_maker_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="cabinet_maker_interest" v-model="store.application.cabinet_maker_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.painting_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.painting_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="painting_experience" :value="index" v-model="store.application.painting_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="painting_interest" v-model="store.application.painting_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.cooking_experience || store.application.waiter_catering_experience || store.application.production_of_bakery_products_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.cooking') }}</td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.cooking_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.cooking_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="cooking_experience" :value="index" v-model="store.application.cooking_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="cooking_interest" v-model="store.application.cooking_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.waiter_catering_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.waiter_catering_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="waiter_catering_experience" :value="index" v-model="store.application.waiter_catering_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="waiter_catering_interest" v-model="store.application.waiter_catering_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.production_of_bakery_products_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.production_of_bakery_products_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="production_of_bakery_products_experience" :value="index" v-model="store.application.production_of_bakery_products_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="production_of_bakery_products_interest" v-model="store.application.production_of_bakery_products_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.composing_experience || store.application.electronic_music_experience || store.application.other_music_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.music') }}</td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.composing_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.composing_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="composing_experience" :value="index" v-model="store.application.composing_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="composing_interest" v-model="store.application.composing_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.electronic_music_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.electronic_music_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="electronic_music_experience" :value="index" v-model="store.application.electronic_music_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="electronic_music_interest" v-model="store.application.electronic_music_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.other_music_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.other_music_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="other_music_experience" :value="index" v-model="store.application.other_music_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="other_music_interest" v-model="store.application.other_music_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.helpdesk_experience || store.application.software_development_experience || store.application.ux_designer_experience || store.application.websites_experience || store.application.other_it_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.it') }}
                                            </td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.helpdesk_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.helpdesk_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="helpdesk_experience" :value="index" v-model="store.application.helpdesk_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="helpdesk_interest" v-model="store.application.helpdesk_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.software_development_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.software_development_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="software_development_experience" :value="index" v-model="store.application.software_development_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="software_development_interest" v-model="store.application.software_development_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.ux_designer_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.ux_designer_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="ux_designer_experience" :value="index" v-model="store.application.ux_designer_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="ux_designer_interest" v-model="store.application.ux_designer_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.websites_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.websites_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="websites_experience" :value="index" v-model="store.application.websites_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="websites_interest" v-model="store.application.websites_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.other_it_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.other_it_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="other_it_experience" :value="index" v-model="store.application.other_it_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="other_it_interest" v-model="store.application.other_it_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.design_experience || store.application.film_production_experience || store.application.film_editing_experience || store.application.animation_experience || store.application.other_media_film_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.media_film') }}</td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.design_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.design_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="design_experience" :value="index" v-model="store.application.design_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="design_interest" v-model="store.application.design_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.film_production_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.film_production_experience') }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="film_production_experience" :value="index" v-model="store.application.film_production_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="film_production_interest" v-model="store.application.film_production_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.film_editing_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.film_editing_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="film_editing_experience" :value="index" v-model="store.application.film_editing_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="film_editing_interest" v-model="store.application.film_editing_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.animation_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.animation_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="animation_experience" :value="index" v-model="store.application.animation_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="animation_interest" v-model="store.application.animation_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.other_media_film_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.other_media_film_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="other_media_film_experience" :value="index" v-model="store.application.other_media_film_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="other_media_film_interest" v-model="store.application.other_media_film_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.sound_experience || store.application.light_experience || store.application.graphics_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-isbre-400 font-bold uppercase">
                                                {{ t('form.knowledgeExperienceAreas.llb') }}</td>
                                            <td v-for="idx in 6" :key="idx" class="bg-isbre-400"></td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.sound_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.sound_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="sound_experience" :value="index" v-model="store.application.sound_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="sound_interest" v-model="store.application.sound_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-neutral-100 border-b border-neutral-300" v-if="store.application.light_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-neutral-100 border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.light_experience')
                                                }}</td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="light_experience" :value="index" v-model="store.application.light_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name=" light_interest" v-model="store.application.light_interest" />
                                            </td>
                                        </tr>
                                        <tr class="bg-white border-b border-neutral-300" v-if="store.application.graphics_experience">
                                            <td class="py-4 px-4 sticky left-0 z-[2] bg-white border-r border-neutral-300"
                                                scope="row">{{ t('form.knowledgeExperienceAreas.graphics_experience') }}
                                            </td>
                                            <td v-for="index in 5" :key="index" class="py-4 px-4 text-center">
                                                <BccRadio disabled name="graphics_experience" :value="index" v-model="store.application.graphics_experience" />
                                            </td>
                                            <td class="py-4 px-4 text-center border-l border-neutral-300">
                                                <BccCheckbox disabled name="graphics_interest" v-model="store.application.graphics_interest" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.work_life_experience')
                        }}</h3>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.msOfficeKnowledge.title') }}</p>
                            <span>{{ store.application.ms_office_knowledge ? t('form.msOfficeKnowledge.' + store.application.ms_office_knowledge) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.other_knowledge.title') }}</p>
                            <span>{{ store.application.other_knowledge || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.military_experience.title') }}</p>
                            <span>{{ store.application.military_experience ? t('form.yesOrNo.' + store.application.military_experience) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.military_remarks.title') }}</p>
                            <span>{{ store.application.military_remarks || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.international_experience_longer_than_3_months.title') }}</p>
                            <span>{{ t('form.yesOrNo.' + store.application.international_experience_longer_than_3_months) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.international_experience_comment.title') }}</p>
                            <span>{{ store.application.international_experience_comment || '-' }}</span>
                        </div>

                        <div class="dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.volunteerExperience.title') }}</p>
                            <span v-for="(value, index) in store.application.volunteer_experience" :key="index">{{ t('form.volunteerExperience.' + value) }}</span>
                            <span v-if="!store.application.volunteer_experience?.length">-</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.volunteer_experience_other_remarks.title') }}</p>
                            <span>{{ store.application.volunteer_experience_other_remarks || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.lived_alone_shared_apartment.title') }}</p>
                            <span>{{ t('form.yesOrNo.' + store.application.lived_alone_shared_apartment) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.leadershipResponsibilities.title') }}</p>
                            <span>{{ store.application.leadership_responsibilities ? t('form.leadershipResponsibilities.' + store.application.leadership_responsibilities) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.motivation_as_team_leader.title') }}</p>
                            <span>{{ t('form.yesOrNo.' + store.application.motivation_as_team_leader) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.motivation_as_team_leader_comment.title') }}</p>
                            <span>{{ store.application.motivation_as_team_leader_comment || '-' }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.travel_info.title') }}</h3>

                        <div class="col-span-full dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.primary_citizenship') }}</p>
                            <span>{{ t('form.citizenship.' + store.application.primary_citizenship) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.passport_first_name.title') }}</p>
                            <span>{{ store.application.passport_first_name || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.passport_last_name.title') }}</p>
                            <span>{{ store.application.passport_last_name || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.passport_issue_date.title') }}</p>
                            <span>{{ store.application.passport_issue_date ?
                                getFormattedDate(store.application.passport_issue_date) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.passport_issue_place.title') }}</p>
                            <span>{{ store.application.passport_issue_place || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.passport_valability.title') }}</p>
                            <span>{{ store.application.passport_valability ?
                                getFormattedDate(store.application.passport_valability) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.passport_number.title') }}</p>
                            <span>{{ store.application.passport_number || '-' }}</span>
                        </div>

                        <div class="dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.secondary_citizenship') }}</p>
                            <span>{{ store.application.secondary_citizenship ? t('form.citizenship.' + store.application.secondary_citizenship) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.norwegian_personal_number.title') }}</p>
                            <span>{{ store.application.norwegian_personal_number || '-' }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.education') }}</h3>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.school_level.title') }}</p>
                            <span>{{ t('form.schoolLevels.' + store.application.school_level) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.school_level_on_start.title') }}</p>
                            <span>{{ t('form.schoolLevels.' + store.application.school_level_on_start) }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.vocational_training.title') }}</h3>

                        <div class="col-span-full">
                            <p class="font-bold mb-1">{{ t('form.vocational_training_status.title') }}</p>
                            <span>{{ store.application.vocational_training_status ? t('form.educationStatus.' + store.application.vocational_training_status) : '-' }}</span>
                        </div>

                        <div class="dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.vocational_training_started.title') }}</p>
                            <span v-for="(value, index) in store.application.vocational_training_started" :key="index">{{ t('form.vocationalTrainingAreas.' + value) }}</span>
                            <span v-if="!store.application.vocational_training_started">-</span>
                        </div>

                        <div class="dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.vocational_training_will_complete.title') }}</p>
                            <span v-for="(value, index) in store.application.vocational_training_will_complete" :key="index">{{ t('form.vocationalTrainingAreas.' + value) }}</span>
                            <span v-if="!store.application.vocational_training_will_complete">-</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.vocational_training_comment.title') }}</p>
                            <span>{{ store.application.vocational_training_comment || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.vocational_training_certified_date.title') }}</p>
                            <span>{{ store.application.vocational_training_certified_date ? getFormattedDate(store.application.vocational_training_certified_date) : '-' }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.studies') }}</h3>

                        <div class="col-span-full">
                            <p class="font-bold mb-1">{{ t('form.studies_status.title') }}</p>
                            <span>{{ store.application.studies_status ? t('form.educationStatus.' + store.application.studies_status) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.study_started_title.title') }}</p>
                            <span>{{ store.application.study_started_title || '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.study_title.title') }}</p>
                            <span>{{ store.application.study_title || '-' }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:table">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.driving') }}</h3>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.driverLicense.title') }}</p>
                            <span>{{ store.application.driver_license ? t('form.driverLicense.' +
                                store.application.driver_license) : '-' }}</span>
                        </div>

                        <div class="dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.driverLicenseClasses.title') }}</p>
                            <span v-for="(value, index) in store.application.driver_license_class" :key="index">{{
                                t('form.driverLicenseClasses.' + value) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.driver_license_since.title') }}</p>
                            <span>{{ store.application.driver_license_since ? getFormattedDate(store.application.driver_license_since) : '-' }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.driver_license_comment.title') }}</p>
                            <span>{{ store.application.driver_license_comment ? store.application.driver_license_comment : '-' }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 mt-16 print:table">
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.communication') }}</h3>

                        <div class="col-span-full">
                            <p class="font-bold mb-1">{{ t('form.preferredCommunicationLanguages.title') }}</p>
                            <span>{{ t('form.preferredCommunicationLanguages.' +
                                store.application.preferred_communication_language) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.norwegian_language_level.title') }}</p>
                            <span>{{ t('form.languageLevels.' + store.application.norwegian_language_level) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.german_language_level.title') }}</p>
                            <span>{{ t('form.languageLevels.' + store.application.german_language_level) }}</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.english_language_level.title') }}</p>
                            <span>{{ t('form.languageLevels.' + store.application.english_language_level) }}</span>
                        </div>

                        <div class="dropdown-multiple">
                            <p class="font-bold mb-1">{{ t('form.languages.title') }}</p>
                            <span v-for="(value, index) in store.application.other_languages" :key="index">{{
                                t('form.languages.' + value) }}</span>
                        </div>
                    </article>

                    <article class="grid md:grid-cols-2 gap-2 md:gap-4 print:hidden">
                        <hr class="col-span-full | border-neutral-300 my-8" />
                        <h3 class="col-span-full | text-xl font-bold text-dark-green-500">{{ t('form.documents') }}</h3>

                        <div :data-doc="document = getDocument('cv')">
                            <span class="bcc-form-label !text-[1rem]">{{ t('form.cv.title') }}</span>
                            <div v-if="document" class="bcc-input-container w-full">
                                <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                                    <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                                    <div class="flex flex-col">
                                        <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                        <span class="text-xs">{{ document.size }}</span>
                                    </div>
                                    <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                                </div>
                            </div>
                            <span v-else>-</span>
                        </div>

                        <div :data-doc="document = getDocument('certificates')">
                            <span class="bcc-form-label !text-[1rem]">{{ t('form.certificates.title') }}</span>
                            <div v-if="document" class="bcc-input-container w-full">
                                <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                                    <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                                    <div class="flex flex-col">
                                        <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                        <span class="text-xs">{{ document.size }}</span>
                                    </div>
                                    <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                                </div>
                            </div>
                            <span v-else>-</span>
                        </div>

                        <div :data-doc="document = getDocument('work_certificates')">
                            <span class="bcc-form-label !text-[1rem]">{{ t('form.work_certificates.title') }}</span>
                            <div v-if="document" class="bcc-input-container w-full">
                                <div class="text-dark-green-100 flex items-center gap-2 py-1.5 px-3 rounded-xl bg-neutral-100" :data-id="document.fieldName">
                                    <span class="material-symbols-rounded text-4xl">{{ document.ext }}</span>
                                    <div class="flex flex-col">
                                        <a :href="directus._url + '/assets/' + document.assetId + '?access_token=' + store.access_token" class="font-bold transition hover:text-dark-green-500" target="_blank" :class="{ 'pointer-events-none': !document.assetId }">{{ document.tempName }}</a>
                                        <span class="text-xs">{{ document.size }}</span>
                                    </div>
                                    <span class="material-symbols-rounded ml-auto cursor-pointer rounded-full transition hover:bg-dark-green-100 hover:text-white" @click="cancelFileUpload($event)">cancel</span>
                                </div>
                            </div>
                            <span v-else>-</span>
                        </div>

                        <div>
                            <p class="font-bold mb-1">{{ t('form.portfolio.title') }}</p>
                            <span><a :href="store.application.portfolio || '#'" target="_blank">{{
                                store.application.portfolio || '-' }}</a></span>
                        </div>
                    </article>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDirectusStore } from '../stores/directus'
import { useRoute } from 'vue-router'
import { KeyboardArrowLeftIcon } from '@bcc-code/icons-vue'
import { BccCheckbox, BccRadio } from '@bcc-code/design-library-vue'

import { getFileSize, getFileExtension } from '../helpers/files.js'
import { getFormattedDate } from '../helpers/fields.js'

import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const route = useRoute()
const directusStore = useDirectusStore()
const user = directusStore.user
const directus = directusStore.directus

const { t } = useI18n({
    inheritLocale: true,
    useScope: 'local'
})

let interest_rates = []
for (let i = 0; i <= 4; i++) {
    interest_rates.push({
        value: i,
        label: t('interest.rate.levels.' + i)
    })
}

const store = reactive({
    access_token: null,
    application: null,

    files: [],
    interest_rates: interest_rates,
    myEvaluation: null,
    interest_rate: null,

    updateFailed: false,
    formLoading: false
})

onBeforeMount(async () => {
    store.access_token = await directus.auth.staticToken

    await getApplication()

    watch(
        () => store.interest_rate,
        (rate) => evaluateApplication(rate)
    )
})

async function getApplication() {
    const application = await directus.items("applications").readByQuery({
        filter: {
            id: { _eq: route.params.id }
        },
        fields: ['*', 'local_church.*', 'profile_picture.*.*', 'cv.*', 'certificates.*', 'work_certificates.*', 'interests.*.*'],
    })
    if (!application.data.length) return

    store.application = application.data[0]

    if (parseInt(route.params.employer_id)) {
        store.myEvaluation = getEmployerEvaluation()
        store.interest_rate = store.myEvaluation?.rate
    }

    setInitialDocuments()
}

function setInitialDocuments() {
    const documents = ['cv', 'certificates', 'work_certificates']

    documents.filter(fieldName => store.application[fieldName]).forEach(fieldName => {
        store.files.push({
            fieldName: fieldName,
            tempName: store.application[fieldName].filename_download,
            ext: getFileExtension(store.application[fieldName].filename_download),
            size: getFileSize(store.application[fieldName].filesize),
            assetId: store.application[fieldName].id
        })
    })
}

function getEmployerEvaluation() {
    return store.application.interests.find(i => i.employer_id.id == route.params.employer_id)
}

function getEmployerName() {
    return getEmployerEvaluation()?.employer_id.legal_entity_name
}

function getDocument(id) {
    return store.files.find(f => f.fieldName == id)
}

async function evaluateApplication(rate) {
    const data = {
        'rate': rate,
        'application_id': parseInt(route.params.id),
        'employer_id': parseInt(route.params.employer_id)
    }

    try {
        store.myEvaluation
            ? await directus.items('interests').updateOne(store.myEvaluation.id, { 'rate': rate })
            : await directus.items('interests').createOne(data)
    }
    catch (error) {
        console.error("There was an error!", error)
    }
}
</script>

<style scoped>
@media print {
    .hide-bg-print {
        background-color: transparent;
    }

    article {
        width: 100%;
    }

    article>div {
        display: inline-block;
        margin-bottom: 1rem;
    }

    article>div.col-span-full {
        width: 100%;
    }

    article>div:not(.col-span-full) {
        width: 50%;
    }

    article>h3 {
        margin-bottom: 1rem;
    }
}
</style>
